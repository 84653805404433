import { Component } from '@verndale/core';
import { animate } from 'motion';

class ExpandContent extends Component {
  setupDefaults() {
    this.dom = {
      toggleButton: this.el.querySelector('.toggle-button'),
      content: this.el.querySelector('.content-to-expand')
    };

    this.open = false;
    this.height = 0;

    this.getContentHeight();
  }

  addListeners() {
    this.dom.toggleButton.addEventListener('click', this.toggle.bind(this));
    window.addEventListener('resize', this.getContentHeight.bind(this));
  }

  getContentHeight() {
    this.dom.content.style.height = 'auto';
    this.height = this.dom.content.offsetHeight;

    if (!this.open) {
      this.dom.content.style.height = 0;
    }
  }

  async toggle(e) {
    if (e) e.preventDefault();
    const { content, toggleButton } = this.dom;
    this.open = !this.open;

    if (this.open) {
      if (this.el.classList.contains('large-accordion')) {
        this.el.classList.add('expanded');
      }

      content.style.visibility = 'visible';
      toggleButton.setAttribute('aria-expanded', true);
      this.getContentHeight();

      await animate(
        content,
        { height: [0, `${this.height}px`] },
        { duration: 0.4 }
      ).finished;
    } else {
      this.getContentHeight();
      await animate(
        content,
        { height: [`${this.height}px`, 0] },
        { duration: 0.4 }
      ).finished;

      content.style.visibility = 'hidden';
      toggleButton.removeAttribute('aria-expanded');

      if (this.el.classList.contains('large-accordion'))
        this.el.classList.remove('expanded');
    }
  }
}

export default ExpandContent;
