import GatedLink from '../GatedLink';

function global() {
  const DataToScroll = () => {
    const nodeList = document.querySelectorAll('[data-smooth-scroll-to]');
    nodeList.forEach(node => {
      node.addEventListener('click', e => {
        e.preventDefault();
        const target = document.querySelector(
          `[name=${node.dataset.smoothScrollTo.replace('#', '')}]`
        );
        if (!target) return;
        target.scrollIntoView({ behavior: 'smooth' });
      });
    });
  };

  DataToScroll();

  const initGatedLink = () => {
    const links = document.querySelectorAll('[data-gated-link]');
    if (!links.length) return;
    links.forEach(module => {
      new GatedLink(module);
    });
  };

  // Jumplinks
  document.body.addEventListener('click', e => {
    if (!e.target.closest('[data-smooth-scroll-to]')) return;
    e.preventDefault();
    const name = e.target.dataset.smoothScrollTo.replace('#', '');
    const target = document.querySelector(`[name="${name}"]`);

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  });

  initGatedLink();
}
export default global;
