import { Component } from '@verndale/core';
import ExpandedContent from './ExpandContent';

class GatedLink extends Component {
  addListeners() {
    this.el.addEventListener('click', this.handleModal.bind(this));
  }

  handleModal(e) {
    e.preventDefault();
    this.initModal();
  }

  closeModal() {
    const modal = document.querySelector('.gated-modal');
    modal.close();
    this.closeBtn.removeEventListener('click', this.closeModal.bind(this));
    this.dialog.removeEventListener('click', this.closeModal.bind(this));
    modal.remove();
    document.body.style = '';
  }

  initModal() {
    this.getModalContent();
  }

  getModalContent() {
    fetch(this.el.dataset.gatedLink)
      .then(res => res.json())
      .then(data => {
        this.data = data;
        this.createModal();
      })
      .catch(err => console.log(err));
  }

  createModal() {
    const modalTemplate = `
      <dialog class="gated-modal" open style="inset-block-start:${
        window.pageYOffset
      }px">
        <div class="gated-modal-container">
          <button class="gated-modal-close">
            <svg>
              <use xlink:href="#close"></use>
            </svg>
          </button>
          <div class="gated-modal-content">
            ${
              this.data.title
                ? `<h1 class="gated-modal__title">${this.data.title}</h1>`
                : ''
            }
            ${this.data.content}
          </div>
          <a href="#" class="button secondary" target="_blank">${
            this.data.btnLabel ? this.data.btnLabel : 'I Agree'
          }</a>
        </div>
      </dialog>`;

    document.body.insertAdjacentHTML('beforeend', modalTemplate);
    document.body.style = 'overflow: clip;position: relative;';

    this.closeBtn = document.querySelector('.gated-modal-close');
    this.submitBtn = document.querySelector('.gated-modal .button');
    this.modules = document.querySelectorAll(
      '.gated-modal [data-module="ExpandContent"]'
    );
    this.dialog = document.querySelector('.gated-modal');

    if (this.dialog) {
      this.dialog.addEventListener('click', e => {
        if (e.target === this.dialog) {
          this.closeModal();
        }
      });
    }

    if (this.modules.length) {
      this.modules.forEach(module => {
        new ExpandedContent(module);
      });
    }

    if (this.submitBtn) {
      this.submitBtn.href = this.el.href;
    }

    this.submitBtn.addEventListener('click', this.closeModal.bind(this));

    if (this.closeBtn) {
      this.closeBtn.addEventListener('click', this.closeModal.bind(this));
    }
  }
}

export default GatedLink;
