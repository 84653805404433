import React from 'react';
import { createRoot } from 'react-dom/client';
import globModules from './glob-modules';

const reactModule = (Component, nodeList) => {
  nodeList.forEach(node => {
    const root = createRoot(node);
    root.render(<Component {...node.dataset} />);
  });
};

const modules = [
  {
    name: 'HomeHero',
    loader: () => import('./modules/HomeHero')
  },
  {
    name: 'Navigation',
    loader: () => import('./modules/Navigation')
  },
  {
    name: 'Modal',
    loader: () => import('./modules/Modal')
  },
  {
    name: 'ExpandContent',
    loader: () => import('./modules/ExpandContent')
  },
  {
    name: 'ExpandSelectBox',
    loader: () => import('./modules/ExpandSelectBox')
  },
  {
    name: 'MultiPageContainer',
    loader: () => import('./modules/MultiPageContainer')
  },
  {
    name: 'PageTransition',
    loader: () => import('./modules/PageTransition')
  },
  {
    name: 'AdvancedSearch',
    loader: () => import('./modules/react/AdvancedSearch'),
    render: reactModule
  },
  {
    name: 'BackToTop',
    loader: () => import('./modules/BackToTop')
  },
  {
    name: 'CircleGraph',
    loader: () => import('./modules/CircleGraph')
  },
  {
    name: 'FixedCta',
    loader: () => import('./modules/FixedCta')
  },
  {
    name: 'SubscribeForm',
    loader: () => import('./modules/SubscribeForm')
  },
  {
    name: 'AdvancedOptions',
    loader: () => import('./modules/AdvancedOptions')
  },
  {
    name: 'SocialShare',
    loader: () => import('./modules/SocialShare')
  },
  {
    name: 'HomeSearch',
    loader: () => import('./modules/HomeSearch')
  },
  {
    name: 'HighlightTabs',
    loader: () => import('./modules/HighlightTabs')
  },
  {
    name: 'GatedLink',
    loader: () => import('./modules/GatedLink')
  }
];

export default [...globModules, ...modules];
